<template>
  <MyLink name="home" class="logo">
    <img class="logo__img" src="/img/logo-short-colored.svg" alt="logo" />
  </MyLink>
</template>

<script setup></script>

<style lang="scss" scoped>
.logo {
  &__img {
    @include fixedHW(32px);
  }
}
</style>
